import React from "react"

import HeaderClients from "../../components/header_clients"
import WomanPic from "../../static/images/Woman.jpg"

export default function Woman() {
  const heroTitle = "Woman"

  return (
    <HeaderClients heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <figure class="image-block-wrapper mb-4">
        <img src={WomanPic} alt={heroTitle} class="img-fluid img-thumbnail" />
      </figure>

      <p>
        The financial needs and requirements of women generally differ from those of men. Women
        might have different circumstances, life expectatiencies, objectives, risk appetite and
        investment time horizons than those of men. We work with you to understand your specific
        needs and help build a solid foundation for a strong financially secured future. Whether you
        are in a relationship, single, divorced or widowed, we assist you in gaining control of your
        finances and be confident regardless of your situation.
      </p>
    </HeaderClients>
  )
}
