import React from "react"
import { Link } from "gatsby"

export default function NavClients() {
  return (
    <>
      <Link to="/" className="back--home">
        <i class="fas fa-chevron-left"></i> <span>Back to homepage</span>
      </Link>

      <ul class="sub--pages sub--pages-sm">
        <li>
          <Link to="/clients/business-owner" activeClassName="is--active">
            Business Owner
          </Link>
        </li>
        <li>
          <Link to="/clients/professional-salaried-employee" activeClassName="is--active">
            Professional
          </Link>
        </li>
        <li>
          <Link to="/clients/woman" activeClassName="is--active">
            Woman
          </Link>
        </li>
        <li>
          <Link to="/clients/retirees" activeClassName="is--active">
            Retirees
          </Link>
        </li>
      </ul>
    </>
  )
}
